import { Row, Col, Tab, Nav, Container } from "react-bootstrap";

import { Education } from "../components/About/EducationTimeline";
import { Experience } from "../components/About/ExperienceTimeline";

import "../css/timeline.css";
import "../css/responsive.css";
import "../css/font-awesome.min.css";
import "../css/accordion.css";

function Timeline() {
  return (
    <>
      <Container fluid style={{ paddingTop: "2rem" }}>
        <div className="center">
          <h2
            id="about"
            style={{
              color: "#a84158",
            }}
          >
            ABOUT
          </h2>
          <h4>#Experience</h4>
          <p>Working with people</p>
        </div>

        <br />

        <Tab.Container defaultActiveKey="first">
          <Row>
            <Col>
              <Nav className="justify-content-end">
                <Nav.Item>
                  <Nav.Link eventKey="first" style={{ border: "0" }}>
                    <div
                      className="center"
                      style={{
                        color: "#a84158",
                        padding: "1rem",
                        border: " 1px solid #a84158",
                        boxShadow: "1px black",
                      }}
                    >
                      <h3>
                        <i className="fa fa-graduation-cap"></i>
                      </h3>{" "}
                      <h3>Education</h3>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col>
              <Nav>
                <Nav.Item>
                  <Nav.Link eventKey="second">
                    {" "}
                    <div
                      className="center"
                      style={{
                        color: "#a84158",
                        padding: "1rem",
                        border: " 1px solid #a84158",
                        boxShadow: "1px black",
                      }}
                    >
                      <h3>
                        <i className="fa fa-paper-plane-o"></i>
                      </h3>

                      <h3>Experience</h3>
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Education />
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Experience />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
      <hr />
    </>
  );
}

export { Timeline };
