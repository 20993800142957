import { Container, Row } from "react-bootstrap";
import { Navbar, Nav } from "react-bootstrap";

export default function HeaderNav() {
  return (
    <>
      <Container fluid>
        <div className="center">
          <h2
            style={{
              textShadow: "1px 1px grey",
              color: "#a84158",
              position: "sticky",
              fontWeight: "400",
            }}
          >
            #Peter_Rambeck_Andersen
          </h2>
        </div>
        <Navbar expand="lg" variant="light" bg="light">
          <Container>
            <Navbar.Brand href="#"></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse
              id="basic-navbar-nav"
              className="justify-content-center"
            >
              <Nav>
                <Nav.Link href="#profile">
                  <h4 style={{ textShadow: "1px 0px grey", color: "#a84158" }}>
                    Profile
                  </h4>
                </Nav.Link>
                <Nav.Link href="#decisionmaker">
                  <h4 style={{ textShadow: "0px 1px grey", color: "#a84158" }}>
                    Decision maker
                  </h4>
                </Nav.Link>
                <Nav.Link href="#about">
                  <h4 style={{ textShadow: "1px 0px grey", color: "#a84158" }}>
                    About
                  </h4>
                </Nav.Link>
                <Nav.Link href="#contact">
                  <h4 style={{ textShadow: "0px 1px grey", color: "#a84158" }}>
                    Contact
                  </h4>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Container>
      <div
        className="spacing"
        style={{ fontSize: "1.2rem", lineHeight: "0.8rem" }}
      >
        <div className="center">
          <p>#Vision in motion</p>
          <p>#Agile #Independent #Experience</p>
        </div>
      </div>
    </>
  );
}
