import { Container, Row, Col, Card } from "react-bootstrap";
import Peter from "../images/portraitPeterRambeckAndersen.jpg";
import Belbin from "../belbin/Andersen_Peter_Rambeck_04122020_091842.pdf";

function Profile(props) {
  const { table } = props;
  return (
    <>
      <Container fluid style={{ paddingTop: "2rem" }}>
        {" "}
        <Row>
          <Col
            className={["center", "spacing"].join(" ")}
            lg={true}
            style={{
              alignSelf: "center",
            }}
          >
            <h2
              id="profile"
              style={{
                color: "#a84158",
              }}
            >
              PROFILE
            </h2>
            <h4>#Independent</h4>
            <p> Always keeping my Vision in motion</p>
          </Col>
        </Row>
        <Row>
          <Col
            className="center"
            lg={true}
            style={{
              alignSelf: "center",
            }}
          >
            <>
              <Card
                style={{
                  border: "0",
                }}
              >
                <Card.Img
                  variant="top"
                  src={Peter}
                  alt="Peter Rambeck Andersen profile picture"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Card>
              <br />
            </>
          </Col>
          <Col className="" style={{ lineHeight: "26px" }}>
            <p>
              2022. <br />
              June, graduated course in computer science:{" "}
              <span style={{ color: "#a84158" }}>'Datamatiker',</span>
              <br />
              School:{" "}
              <a
                href="https://www.cphbusiness.dk/uddannelser/erhvervsakademiuddannelser/datamatiker"
                style={{ color: "black" }}
              >
                <span class="highLight1">Copenhagen business academy.</span>
              </a>
              <br />
              Fullstack:{" "}
              <span style={{ textShadow: "0.5px 0px #a84158" }}>
                Java. JS. React. Python. DevOps.
              </span>
              <br />
            </p>
            <p>
              2008 to 2019. <br />
              Startup: <br /> I ran own highly specialized niche inbound travel
              agency from Bangkok, Thailand. <br /> Under own marketing brand:
              ’Indochina Explorers’, I was covering destinations in the greater
              Southeast Asia region.
            </p>
            <p>
              Prior to startup initiative I have a rich experience fulfilling a
              good number managerial positions abroad.
              <br />
              Working with people from different cultures often in challenging
              business environments, has developed independency as agility.
            </p>
            <br />

            <Table table={table} />

            {/*

            <a
              href="../images/cvEnOLPeterRambeckAndersen.pdf"
              class="button button-large button-border button-black button-dark noleftmargin"
              download
            >
              <i class="icon-line-cloud-download"></i> Download CV
            </a>
             */}
          </Col>
        </Row>
      </Container>
      <hr />
    </>
  );
}

export { Profile };

function Table(props) {
  return (
    <>
      <table class="table">
        <tbody>
          <tr>
            <td class="notopborder">
              <strong>Name:</strong>
            </td>
            <td class="notopborder">Peter Rambeck Andersen</td>
          </tr>

          <tr>
            <td>
              <strong>Nationality:</strong>
            </td>
            <td>Danish</td>
          </tr>

          <tr>
            <td>
              <strong>Birth date:</strong>
            </td>
            <td>27 06 1969</td>
          </tr>

          <tr>
            <td>
              <strong>Status</strong>
            </td>
            <td>Married</td>
          </tr>

          <tr>
            <td>
              <strong>Phone:</strong>
            </td>
            <td>+45 50 30 01 92</td>
          </tr>

          <tr>
            <td>
              <strong>Linkedin:</strong>
            </td>

            <td>
              {" "}
              <a
                href="https://www.linkedin.com/in/peter-rambeck-andersen/"
                target="_blank"
                rel="noreferrer"
              >
                Link
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <strong>Belkin profile:</strong>
            </td>
            <td>
              {" "}
              <a href={Belbin} download>
                Click to download
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
