import HeaderNav from "./components/Navbar";
import { ProfilePage } from "./pages/SPA";

function App() {
  return (
    <>
      <HeaderNav />
      <ProfilePage />
    </>
  );
}

export default App;
