import { Row, Col } from "react-bootstrap";

function Education() {
  return (
    <>
      <Row style={{ marginTop: "4vw", marginBottom: "4vw" }}>
        <Col>
          <section id="about" className="about about-timeline-style">
            <h2
              id="timeline"
              className="timeline-badge"
              style={{
                textAlign: "center",
                color: "#a84158",
              }}
            >
              SCHOOL <br />
              TIMELINE
            </h2>

            <hr />
            <ul className="timeline">
              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2020 - 2022</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Datamatiker, Computer science</h4>
                    <h5>
                      CPHBusiness
                      <br />
                      Copenhagen business academy
                    </h5>
                    <p>
                      Full time 2.5 years computer science.
                      <br />
                      #Programming, #Systemdevelopment, #Project management{" "}
                      <br />
                      <br />
                      Java, React, JavaScript, Python, Security, Robot tech.,
                      Scrum
                    </p>
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>

              {/* <!-- end timeline-right --> */}

              <li className="timeline-left">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date">2018 - 2019</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Programming & Problem-solving</h4>
                    <h5>University of Copenhagen </h5>
                    <p>
                      Faculty of Science
                      <br />
                      Computer science course
                    </p>
                    <p>F#</p>
                  </div>
                </div>
                {/* <!--end timeline-panel --> */}
              </li>

              {/* <!-- end timeline-left --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2008</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Executive MBA</h4>
                    <h5>ABAC, Assumption University, Bangkok</h5>
                    <p>Fast track 7 months international MBA,</p>
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-left">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date">2018 - 2019</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Graduate Diploma Business Administration</h4>
                    <h5>CBS, Copenhagen Business School</h5>
                    <p>
                      HD 1 part
                      <br />2 years
                    </p>
                  </div>
                </div>
                {/* <!--end timeline-panel --> */}
              </li>

              {/* <!-- end timeline-left --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">1996 - 1998</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Diploma in Marketing</h4>
                    <h5>Niels Brock, Copenhagen Business College</h5>
                    <p>Merkonom course in marketing</p>
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-left">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date">1986 - 1989</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>High School</h4>
                    <h5>Hvidovre Gymnasium</h5>
                    <p>Mathematics & Science</p>
                  </div>
                </div>
                {/* <!--end timeline-panel --> */}
              </li>

              {/* <!-- end timeline-left --> */}
            </ul>
            {/* <!-- end timeline --> */}
          </section>
        </Col>
      </Row>
    </>
  );
}

export { Education };
