import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { Collapse } from "react-bootstrap";

function Experience() {
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <>
      <Row style={{ marginTop: "4vw", marginBottom: "4vw" }}>
        <Col>
          <section id="about" className="about about-timeline-style">
            <h2
              id="timeline"
              className="timeline-badge"
              style={{
                textAlign: "center",
                color: "#a84158",
              }}
            >
              CONDENSED <br />
              TIMELINE
            </h2>

            <hr />
            <ul className="timeline">
              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2008 - 2019</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Indochina Explorers</h4>
                    <h5 style={{ color: "black" }}>Bangkok, Thailand</h5>
                    <h5>
                      Founder
                      <br />
                    </h5>
                    <p>
                      Startup
                      <br />
                      Inbound specialized travel services.
                      <br />
                      <br />A business model covering the ’Indochina’ region was
                      started in partnership with local agency S.I. Tours (1996)
                      for Thailand, as well as a network of local partners in
                      Vietnam, Malaysia, Laos, Cambodia, China and Japan.
                    </p>

                    <div>
                      <div
                        id="First"
                        onClick={() => setOpen(!open)}
                        aria-controls=""
                        aria-expanded={open}
                      >
                        <span style={{ color: "#a84158" }}>See more ..</span>
                      </div>
                      <Collapse in={open}>
                        <div id="">
                          <p style={{ marginTop: "1rem" }}>
                            {" "}
                            Under my brand: 'Indochina Explorers', I contracted
                            suppliers, product developed and marketed travel
                            products and MICE services to travel agencies and
                            corporations globally.
                            <br />
                            Though at times working on projects with clients, my
                            role became more on consulting for new business
                            niche designs.
                            <br />
                            <br />
                            As part of the business model I developed a supplier
                            network with the capability for extensive cycling
                            programs, Educational & sport programs, premium
                            experiences and more to support my global clients.
                          </p>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </div>
                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-left">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date">1996 - 2008</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>
                      Various destinations, <br />
                      <p>(outline: see timeline)</p>
                    </h5>
                    <h5>Area Manager (a.o.)</h5>
                    <p>
                      Destination management for travel services.
                      <br />
                      <br />
                      Prior to my startup I had an extensive career with Thomas
                      Cook Northern Europe, which took me through a great deal
                      of functions and business areas in Denmark as well as
                      internationally.
                    </p>
                    <div>
                      <div
                        id="Second"
                        onClick={() => setShow(!show)}
                        aria-controls=""
                        aria-expanded={open}
                      >
                        <span style={{ color: "#a84158" }}>See more ..</span>
                      </div>
                      <Collapse in={show}>
                        <div id="">
                          <p style={{ marginTop: "1rem" }}>
                            From handling ad planning large scale operations and
                            leading teams in Thailand, Vietnam, Malaysia, India,
                            Croatia, England, Egypt.
                            <br />
                            To tech project implementation as a strategic tool
                            for HR capacity optimization through a large
                            organisation, Copenhagen.
                            <br />
                            Holding various positions and managing different
                            responsibilities over the period.
                          </p>
                        </div>
                      </Collapse>
                    </div>
                    <br />
                    See specifics under: Extended timeline.
                  </div>
                </div>
                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-left --> */}
            </ul>

            {/* <!-- end timeline --> */}

            <h2
              id="timeline"
              className="timeline-badge"
              style={{
                textAlign: "center",
                color: "#a84158",
              }}
            >
              EXTENDED <br />
              TIMELINE
            </h2>

            <hr />

            <ul className="timeline">
              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2006 - 2008</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>Bangkok, Thailand</h5>
                    <h5>Assisting Area Manager / Contract coordinator.</h5>
                    Responsibilities:
                    <br />
                    <p>
                      Coordinating contracts, Charter & scheduled flight (FIT)
                      operations, reservations and optimizing overall inventory,
                      cash flow analysis & partner relations in an extensive net
                      of vendors in Thailand, Vietnam & Cambodia.
                      <br />
                    </p>
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2004 - 2006</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>
                      Ho Chi Minh City, Vietnam
                    </h5>
                    <h5>Area Manager</h5>
                    Two seasons in the period of each 7 months.
                    <br />
                    Manage Vietnam operation with Scandinavian team & local
                    agent.
                    <br />
                    Large scale operations.
                    <br />
                    <br />
                    Orchestrating group travels and FIT's, overland from north
                    to South Vietnam in a network of local agents & hotel
                    vendors.
                    <br />
                    Contracting, manage suppliers, optimize contracted
                    inventory, sub-agents and cash-flow in all of Vietnam.
                    <br />
                    <br />
                    Responsibilities:
                    <br />
                    <p>
                      Local office set-up & daily operation, setting teams and
                      performance, coordinating Scandinavian as local staff.{" "}
                      <br />
                      Day to day operation, Quality management & reporting, HR
                      performance, planning, future production input, budget
                      optimizing, cash-flow, research & product development
                    </p>
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2005</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>Dubrovnik, Croatia</h5>
                    <h5>Area Manager, Croatia & Montenegro</h5>

                    <p>
                      In between Vietnam seasons I was posted as Area Manager
                      for a vast number of destinations spread out in the area.
                      <br />
                      <br />
                      Handling charter-flights from Scandinavia over a 5 months
                      period covering the Dubrovnik area, ranging from the
                      island of Korcula to, -and incl. Montenegro.
                      <br />
                      <br />
                      Responsibilities:
                      <br />
                      Vendor and hotel contracting, coordinating and managing
                      teams of Scandinavian and local staff in a network of 3
                      different agents in 2 countries.
                      <br />
                      Research and product development, business development
                      reporting, budget optimizing, cash flow.
                    </p>
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2004</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>Phuket, Thailand</h5>
                    <h5>
                      Substituting the Area Manager on a long leave period.
                    </h5>
                  </div>
                </div>
                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2005</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>Goa, India</h5>
                    <h5> Substituting Area Manager</h5>
                    Started season as office & cash flow manager, though as team
                    changes occurred, I was given the responsibility for the
                    overall Destination management.
                    <br />
                    <br />
                    Responsibilities:
                    <br />
                    <p>
                      Tasks ranged from day to day charter operations, managing
                      teams (Scandinavian as local), cash flow, statistics &
                      reporting to HQ & season planning, business development.
                      <br />
                      <br />
                      Project: Due to recession in the industry the flexibility
                      of all human resources had to be optimized.
                    </p>
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2005</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>England, London</h5>
                    <h5>MICE manager</h5>

                    <p>
                      Responsibilities:
                      <br />
                      Implementing and handling & MICE activities ( Meeting,
                      Incentive, Conference, Event ) for a new subdivision
                      within the larger organization consisting of German,
                      Austrian & Swiss tour operators.
                    </p>
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2002 - 2003</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Freelance</h4>
                    <h5 style={{ color: "black" }}>Kuala Lumpur, Malaysia</h5>

                    <p>
                      Local projects for Mayflower:
                      <br />
                      On ad hoc basis, I was representing Thomas Cook Northern
                      Europe ( as others ) in Kuala Lumpur through local agent,
                      Mayflower.
                    </p>
                  </div>
                </div>
                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2002</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>Phuket, Thailand</h5>
                    <h5>Regional Accounting Manager, Indochina</h5>

                    <p>
                      Project:
                      <br />
                      Covering the area of Malaysia, India, the Maldives and day
                      to day operations in Phuket.
                      <br />
                      <br />
                      As part of implementing a new strategy to centralize the
                      overall management for the Asian region, i had to build up
                      a team and an Asian centre in Phuket for all Thomas Cooks’
                      operations incl. financing and budget controlling.
                      <br />I was to re-engineer business -processes, -models
                      and -relations to our many agents in Asia
                    </p>
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2001 - 2002</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>Hurgharta, Egypt</h5>
                    <h5> Assisting operations</h5>
                    Assisting operations to design setup for upcoming high
                    season.
                    <br />
                    <br />
                    Responsibilities:
                    <br />
                    I was called in for a three months period to Egypt. <br />
                    My task was to clear company accounts with our Egyptian
                    co-operating agent and set up a new set of business routines
                    for the upcoming high season operations.
                    <br />
                    Though after the 11th of September incident in America the
                    task changed objective and became to downsize a planned
                    large scale operation without conflicting with our local
                    relations.
                    <br />
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2001</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>Phuket, Thailand</h5>
                    <h5> Operation assistance</h5>
                    Together with teh Area Manager, setting up routines for the
                    destination and training staff.
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2000 - 2001</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>Langkawi, Malaysia</h5>
                    <h5>Charter operations</h5>
                    Destination hotel reservations & cash flow <br />
                    <br />
                    <p>
                      Responsibilities:
                      <br />
                      Thomas Cooks’ charter operations and accounts in Malaysia
                      and Singapore.
                    </p>
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">2000</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>Grand Canaria, Spain</h5>
                    <h5>Reservations</h5>
                    Part of the larger hotel reservation-management team
                  </div>
                </div>
                {/* <!--end timeline-panel --> */}
              </li>
              {/* <!-- end timeline-right --> */}

              <li className="timeline-right">
                <div className="timeline-badge">
                  <i className="fa fa-calendar"></i>
                  <p className="date-inverted">1996 - 2000</p>
                </div>

                {/* <!-- end timeline-badge -->  */}

                <div className="timeline-panel">
                  <div className="experience-content">
                    <h4>Thomas Cook Northern Europe</h4>
                    <h5 style={{ color: "black" }}>Copenhagen, Denmark</h5>
                    <h5> Business optimization / Project mgr.</h5>

                    <p>
                      Strategic planning for Marketing Units and brands within
                      Scandinavia.
                      <br />
                      <br />
                      Managing a small team for early identification of possible
                      sales budget deviation with consequence calculation.
                      <br />
                      Flight-Hotel-Volume optimization according to
                      identification. <br />
                      Optimizing of sales target and discount budget in-and-out
                      of campaign activities.
                      <br />
                      Product development and marketing inputs for sales.
                      <br />
                      <br />
                      Project: <br />
                      Implementing an advanced ( at the time ) rerouting call
                      allocating system in the Denmark organization based on a
                      search mechanism for optimizing HR.
                    </p>
                    <br />
                  </div>
                </div>

                {/* <!--end timeline-panel --> */}
              </li>
            </ul>

            {/* <!-- end timeline --> */}
          </section>
        </Col>
      </Row>
    </>
  );
}

export { Experience };

/*
<Accordion
flush
style={{
  padding: "0",
  margin: "0",
  backgroundColor: "transparent",
}}
>
<Accordion.Item
  eventKey="0"
  style={{
    padding: "0",
    margin: "0",
    backgroundColor: "transparent",
  }}
>
  <Accordion.Header
    style={{
      padding: "0",
      margin: "0",
      backgroundColor: "transparent",
    }}
  >
    see more  
  </Accordion.Header>
  <Accordion.Body style={{ padding: "0", margin: "0" }}>
    <p style={{marginTop:'1rem'}}>
      {" "}
     
    </p>
  </Accordion.Body>
</Accordion.Item>
</Accordion>



    <Accordion
                      flush
                      style={{
                        padding: "0",
                        margin: "0",
                        backgroundColor: "transparent",
                      }}
                    >
                      <Accordion.Item
                        eventKey="0"
                        style={{
                          padding: "0",
                          margin: "0",
                          backgroundColor: "transparent",
                        }}
                      >
                        <Accordion.Header
                          style={{
                            padding: "0",
                            margin: "0",
                            backgroundColor: "transparent",
                          }}
                        >
                          see more
                        </Accordion.Header>
                        <Accordion.Body style={{ padding: "0", margin: "0" }}>
                          <p style={{ marginTop: "1rem" }}>
                            {" "}
                            Under my brand: 'Indochina Explorers', I contracted
                            suppliers, product developed and marketed travel
                            products and MICE services to travel agencies and
                            corporations globally.
                            <br />
                            Though at times working on projects with clients, my
                            role became more on consulting for new business
                            niche designs.
                            <br />
                            <br />
                            As part of the business model I developed a supplier
                            network with the capability for extensive cycling
                            programs, Educational & sport programs, premium
                            experiences and more to support my global clients.
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
*/
