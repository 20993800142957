/* eslint-disable react/jsx-no-comment-textnodes */
import { Container } from "react-bootstrap";
import { DecisionMaker } from "../components/DecisionMaker";
// import { Skills } from "../components/Skills";
import { Profile } from "../components/Profile";
import Header from "../components/Header";
import { Timeline } from "../components/Timeline";
import { ContactForm } from "../components/Contact";

import "../css/style.css";

function ProfilePage() {
  return (
    <>
      <Header />
      <Container>
        <Profile />
        <DecisionMaker />
        <Timeline />
        <ContactForm />
        {/* <Skills /> */}
      </Container>
    </>
  );
}

export { ProfilePage };
