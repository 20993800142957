import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import emailjs from "emailjs-com";

function ContactForm() {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_mr564y8",
        "template_bvyzcot",
        e.target,
        "user_xaZbslSfjZY6U5nkUTrlc"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div>
      <h2
        id="contact"
        style={{
          textAlign: "center",
          color: "#a84158",
        }}
      >
        CONTACT ME
      </h2>
      <div className="container">
        <form onSubmit={sendEmail}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>name</Form.Label>
            <Form.Control input type="text" placeholder="Name" name="name" />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              placeholder="Email address"
              name="email"
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type="text"
              placeholder="What's this all about?"
              name="subject"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput4">
            <Form.Label>Drop me a note</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="What can I do for you?"
              name="message"
            />
          </Form.Group>

          <Button type="submit">Click to send</Button>
        </form>
      </div>
      <hr />
    </div>
  );
}

export { ContactForm };
